import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/commons/Layout";
import Home from "./components/pages/Home";
import Success from "./components/pages/Success";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="success" element={<Success />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
