import { Link } from "react-router-dom";
import SkillsAnimation from "../commons/SkillsAnimation";
import RecentPosts from "../commons/RecentPosts";
import OurServices from "../commons/OurServices";
import Alert from "../commons/Alert";
import Footer from "../requires/Footer";
import Pricing from "../commons/Pricing";
import AppointmentForm from "../commons/AppointmentForm";

export default function Home() {
    return (
        <section>
            <section className="">
                <div className="min-h-[10em] mx-auto max-w-screen-xl px-4 py-52 lg:flex lg:h-screen lg:items-center">
                    <div className="mx-auto max-w-xl text-center">
                        <h1 className="text-3xl font-extrabold sm:text-5xl">
                            I'm Mohammed Sohib
                            <strong className="font-extrabold sm:block"> Freelance Web Designer</strong>
                        </h1>

                        <p className="mt-4 sm:text-xl/relaxed">
                            I'm Collaborated with expert freelancers in technology domains: eg. Frontend, Backend, Database Management & UI/UX Designing
                        </p>

                        <div className="mt-8 flex flex-wrap justify-center gap-4">
                            <a target="_blank" href="https://www.linkedin.com/in/mdsohib" className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-full p-3 px-5 text-sm  tracking-wide border border-black focus-visible:outline-none disabled:cursor-not-allowed disabled:shadow-none">
                                <span>Connect With Me</span>
                            </a>

                            <a href={"#book"} className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-full p-3 px-5 text-sm  tracking-wide text-white bg-black focus-visible:outline-none disabled:cursor-not-allowed disabled:shadow-none">
                                <span>Book Appointment</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>


            <section className="w-full flex justify-center py-10 shadow-lg border-t border-b shadow-gray-50 border-gray-100">
                <div className="w-full max-w-screen-xl overflow-hidden">
                    <SkillsAnimation />
                </div>
            </section>


            <section className="w-full flex justify-center py-20">
                <div className="w-full max-w-screen-xl overflow-hidden">
                    <OurServices />
                </div>
            </section>


            <section className="w-full flex justify-center">
                <div className="w-full max-w-screen-xl overflow-hidden">


                    <section>
                        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                                    <img
                                        alt="Web Design"
                                        src="https://images.pexels.com/photos/114820/pexels-photo-114820.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        className="absolute inset-0 h-full w-full object-cover"
                                    />
                                </div>

                                <div className="lg:py-24">
                                    <h2 className="text-3xl font-bold sm:text-4xl">How to Choose the Perfect Web Design</h2>

                                    <ul className="mt-4 list-disc list-inside space-y-2 text-gray-600">
                                        <li><strong>Know Your Audience:</strong> Design for the people who will use it.</li>
                                        <li><strong>Focus on UX:</strong> Simple, fast, and easy navigation.</li>
                                        <li><strong>Mobile-Ready:</strong> Ensure it looks great on all devices.</li>
                                        <li><strong>Consistent Branding:</strong> Use colors, fonts, and styles that match your brand.</li>
                                    </ul>

                                    <Link
                                        href="#"
                                        className="mt-8 inline-block rounded-full bg-black px-12 py-3 text-sm font-medium text-white transition hover:bg-neutral-800 focus:outline-none focus:ring focus:ring-yellow-400"
                                    >
                                        Download Free E-Book
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>


                </div>
            </section>


            {/* <section className="w-full flex justify-center bg-black">
                <div className="w-full max-w-screen-xl overflow-hidden">
                    <Alert />
                </div>
            </section> */}


            <section className="w-full flex justify-center border-t bg-black" id="book">
                <div className="w-full max-w-screen-xl overflow-hidden">
                    <AppointmentForm />
                </div>
            </section>


            <section className="w-full flex justify-center border-t">
                <div className="w-full max-w-screen-xl overflow-hidden">
                    <Footer />
                </div>
            </section>

        </section>
    )
}