import React, { useEffect } from "react"
import Glide from "@glidejs/glide"
import htmlIcon from '../../assets/technologies-icons/devicon--html5.png'
import cssIcon from '../../assets/technologies-icons/devicon--css3.png'
import jsIcon from '../../assets/technologies-icons/logos--javascript.png'
import reactIcon from '../../assets/technologies-icons/logos--react.png'
import tailwindIcon from '../../assets/technologies-icons/devicon--tailwindcss.png'
import phpIcon from '../../assets/technologies-icons/logos--php.png'
import laravelIcon from '../../assets/technologies-icons/logos--laravel.png'
import pythonIcon from '../../assets/technologies-icons/logos--python.png'
import javaIcon from '../../assets/technologies-icons/logos--java.png'
import mongodbIcon from '../../assets/technologies-icons/devicon--mongodb-wordmark.png'

export default function SkillsAnimation() {
  useEffect(() => {
    const slider = new Glide(".glide-09", {
      type: "carousel",
      autoplay: 1,
      animationDuration: 5500,
      animationTimingFunc: "linear",
      perView: 6,
      classes: {
        nav: {
          active: "[&>*]:bg-wuiSlate-700",
        },
      },
      breakpoints: {
        1024: {
          perView: 6,
        },
        640: {
          perView: 2,
          gap: 10,
        },
      },
    }).mount()

    return () => {
      slider.destroy()
    }
  }, [])

  return (
    <>
      {/*<!-- Component: Testimonial carousel --> */}
      <div className="glide-09 relative w-full">
        {/* <!-- Slides --> */}
        <div data-glide-el="track" className="m-auto">
          <ul className="whitespace-no-wrap flex-no-wrap [backface-visibility: hidden] [transform-style: preserve-3d] [touch-action: pan-Y] [will-change: transform] relative flex gap-0 w-full overflow-hidden p-0">
            <li><img width={'50px'} height={'auto'} src={htmlIcon} alt="" /></li>
            <li><img width={'50px'} height={'auto'} src={cssIcon} alt="" /></li>
            <li><img width={'50px'} height={'auto'} src={jsIcon} alt="" /></li>
            <li><img width={'50px'} height={'auto'} src={reactIcon} alt="" /></li>
            <li><img width={'50px'} height={'auto'} src={tailwindIcon} alt="" /></li>
            <li><img width={'50px'} height={'auto'} src={mongodbIcon} alt="" /></li>
            {/* <li><img width={'50px'} height={'auto'} src={phpIcon} alt="" /></li> */}
            <li><img width={'50px'} height={'auto'} src={laravelIcon} alt="" /></li>
            <li><img width={'50px'} height={'auto'} src={pythonIcon} alt="" /></li>
            {/* <li><img width={'50px'} height={'auto'} src={javaIcon} alt="" /></li> */}
          </ul>
        </div>
      </div>
      {/*<!-- End Testimonial carousel --> */}
    </>
  )
}
