import { Link } from "react-router-dom";

export default function Success (){
    return(
        <section className="h-full">
            <section className="w-full mt-60 text-center text-green-500 text-2xl p-10">
                Appointment Booked Successfully!
                <div>
                    <Link to={'/'} className="text-base bg-black text-white p-3 px-5 rounded-full mt-2 inline-block">Back To Home</Link>
                </div>
            </section>
        </section>
    )
}