import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-screen-xl px-4 pb-8 pt-16 sm:px-6 lg:px-8">
        {/* Subscription Form */}
        {/* <div className="mx-auto max-w-md">
          <h2 className="block text-center text-xl font-bold text-gray-900 sm:text-3xl">
            Stay updated with the latest news!
          </h2>

          <form className="mt-6">
            <div className="relative max-w-lg">
              <label className="sr-only" htmlFor="email"> Email </label>
              <input
                className="w-full rounded-full border-gray-200 bg-gray-100 p-4 pe-32 text-sm font-medium"
                id="email"
                type="email"
                placeholder="Enter your email"
              />
              <button
                className="absolute end-1 top-1/2 -translate-y-1/2 rounded-full bg-blue-600 px-5 py-3 text-sm font-medium text-white transition hover:bg-blue-700"
                type="submit"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div> */}

        {/* Footer Links and Info */}
        <div className="mt-16 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-32">
          {/* Company Description */}
          <div className="mx-auto max-w-sm lg:max-w-none">
            <p className="mt-4 text-center text-gray-500 lg:text-left lg:text-lg">
              We provide high-quality services in web development, app design, and more. Our team is
              dedicated to delivering top-notch solutions tailored to your business needs.
            </p>

            {/* Social Media Links */}
            <div className="mt-6 flex justify-center gap-4 lg:justify-start">
              <SocialMediaLink platform="Facebook" url="#" />
              <SocialMediaLink platform="Instagram" url="#" />
              <SocialMediaLink platform="Twitter" url="#" />
              <SocialMediaLink platform="GitHub" url="#" />
              <SocialMediaLink platform="Dribbble" url="#" />
            </div>
          </div>

          {/* Quick Links */}
          <div className="grid grid-cols-1 gap-8 text-center lg:grid-cols-3 lg:text-left">
            {/* Services Links */}
            <QuickLinks title="Services">
              <FooterLink name="Marketing" url="#" />
              <FooterLink name="Graphic Design" url="#" />
              <FooterLink name="App Development" url="#" />
              <FooterLink name="Web Development" url="#" />
            </QuickLinks>

            {/* About Links */}
            <QuickLinks title="About">
              <FooterLink name="About Us" url="#" />
              <FooterLink name="Careers" url="#" />
              <FooterLink name="History" url="#" />
              <FooterLink name="Our Team" url="#" />
            </QuickLinks>

            {/* Support Links */}
            <QuickLinks title="Support">
              <FooterLink name="FAQs" url="#" />
              <FooterLink name="Contact Us" url="#" />
              <FooterLink name="Live Chat" url="#" />
            </QuickLinks>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="mt-16 border-t border-gray-100 pt-8">
          <p className="text-center text-xs text-gray-500">
            {/* © {new Date().getFullYear()} Company. All rights reserved. <br />
            Developed with */}
            <Link
              href="https://laravel.com/"
              target="_blank"
              rel="noreferrer"
              className="text-gray-700 underline transition hover:text-gray-700/75 ml-1"
            >
              Mohammed Sohib
            </Link>

            <Link
              href="https://laravel-livewire.com/"
              target="_blank"
              rel="noreferrer"
              className="text-gray-700 underline transition hover:text-gray-700/75 ml-1"
            >
              Web Designer
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

// Helper Components for Clean Structure

const FooterLink = ({ name, url }) => (
  <li>
    <Link className="text-gray-700 transition hover:text-gray-700/75" to={url}>
      {name}
    </Link>
  </li>
);

const QuickLinks = ({ title, children }) => (
  <div>
    <strong className="font-medium text-gray-900">{title}</strong>
    <ul className="mt-6 space-y-1">{children}</ul>
  </div>
);

const SocialMediaLink = ({ platform, url }) => (
  <a
    className="text-gray-700 transition hover:text-gray-700/75"
    href={url}
    target="_blank"
    rel="noreferrer"
  >
    <span className="sr-only">{platform}</span>
    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
      {/* Icon SVG paths can go here based on platform */}
    </svg>
  </a>
);

export default Footer;