export default function AppointmentForm() {
    return (
        <section className="">
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
                    {/* Contact Information */}
                    <div className="lg:col-span-2 lg:py-12">
                        <h1 className="max-w-xl text-4xl font-semibold text-white mt-16">
                            Book an Appointment
                            <br />
                            - or -
                            <br />
                            Reach Us
                            <br />
                            <div className="text-base">
                                <a href="tel:+919953259341" className="block"><strong>Phone:</strong> +91 9953259341</a>
                                <a href="mailto:work.sohib@outlook.com" className="block"><strong>Email:</strong> work.sohib@outlook.com</a>
                            </div>
                        </h1>
                    </div>

                    {/* Form Section */}
                    <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
                        <form action="https://api.web3forms.com/submit" method="POST" className="space-y-4">
                            <input type="hidden" name="access_key" value="5a7c05ee-266c-4332-9dc8-7701c86ca028" />
                            {/* Honeypot Spam Protection */}
                            <input type="checkbox" name="botcheck" class="hidden" style={{ display: "none" }} />

                            {/* Custom Confirmation / Success Page */}
                            <input type="hidden" name="redirect" value="https://www.sohib.in/success" />
                            {/* Name Field */}
                            <div>
                                <label className="sr-only" htmlFor="name">Name</label>
                                <input
                                    className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                    placeholder="Your Name"
                                    type="text"
                                    id="name"
                                    required
                                    name="name"
                                />
                            </div>

                            {/* Email and Phone Fields */}
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                <div>
                                    <label className="sr-only" htmlFor="email">Email</label>
                                    <input
                                        className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                        placeholder="Your Email Address"
                                        type="email"
                                        id="email"
                                        required
                                        name="email"
                                    />
                                </div>

                                <div>
                                    <label className="sr-only" htmlFor="phone">Phone</label>
                                    <input
                                        className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                        placeholder="Your Phone Number"
                                        type="tel"
                                        id="phone"
                                        required
                                        name="phone"
                                    />
                                </div>


                                <div>
                                    <label className="sr-only" htmlFor="phone">Phone</label>
                                    <input
                                        className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                        placeholder="Your Country"
                                        type="text"
                                        id="phone"
                                        required
                                        name="country"
                                    />
                                </div>


                                <select name="subject" className="p-2.5 rounded-xl border">
                                    <option value="" disabled selected>--- Choose an Subject ----</option>
                                    <option value="Web Designing">Web Designing</option>
                                    <option value="UI/UX Designing">UI/UX Designing</option>
                                    <option value="Other">Other</option>
                                </select>

                                <button
                                    type="submit"
                                    className="inline-block mt-4 w-full rounded-full bg-black px-5 py-3 font-medium text-white sm:w-auto"
                                >
                                    Book Appointment
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}